header{
  background-color: rgba(15,23, 28, 0.8) !important;
  padding: 0 !important;
  position: unset;
}
.choose-radio > label {
  color: black;
}

.btn-info{
  background-color: #DEB00B !important;
}
.sa-confirm-button-container>.confirm{
  //background-color: green !important;
  //background-color: #141811ed !important;
  background: linear-gradient(90deg, #6ee0ff, #885eff 37.5%, #fab0fc) !important;
  padding: 5px 19px;
}
.sweet-alert button.cancel {
  padding: 5px 19px;
  background-color: #201c1c !important;
}
.sweet-alert {
  background-color: rgba(0,0,0,0.9);

  h2{
    color: whitesmoke !important;
  }
  p{
    color: whitesmoke !important;
  }
}
.sweet-alert .sa-icon.sa-success::before, .sweet-alert .sa-icon.sa-success::after {
  background-color: #000;
}
.sweet-alert .sa-icon.sa-success .sa-fix {
  background-color: #000;
}
