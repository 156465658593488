.text-block, .input-block > .title{
  color: whitesmoke ;
}
#root {
  background-color: rgb(3,15,33);
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}
